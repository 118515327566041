<template>
  <section class="top-ctrl-menu pl-0 pl-md-12">
    <v-row class="justify-space-between align-center mx-0">
      <section class="ctrl-btn">
        <v-checkbox
          v-model="ctrlData.isAll"
          :value="3"
        ></v-checkbox>
      </section>

      <v-menu bottom left transition="slide-y-transition"
        width="250"
        :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <div class="ctrl-btn__item mx-5 my-2"
            :class="{ 'ctrl-btn__item_disabled': !!!elements.length }"
            @click="openPicker">
              <span>Изменить дату</span>
          </div>

          <div v-if="$route.name === 'PromoList'" 
            class="ctrl-btn__item mx-5 my-2"
            :class="[
              { 'ctrl-btn__item_disabled': !!!elements.length },
              { 'ctrl-btn__item_disabled': !isToTest },
            ]"
            @click="sendNewStatus({ arr: elements, inTest: true })">
              <span>Проверить</span>
          </div>

          <div class="ctrl-btn__item mx-5 my-2"
            :class="[
              { 'ctrl-btn__item_disabled': !!!elements.length },
              { 'ctrl-btn__item_disabled': !isToPublic },
            ]"
            @click="sendNewStatus({ arr: elements, inTest: true, inPublic: true })">
              <span>Опубликовать</span>
          </div>

          <div class="ctrl-btn__item mx-5 my-2"
            :class="{ 'ctrl-btn__item_disabled': !!!elements.length }"
            @click="sendNewStatus({ arr: elements })">
              <span>Снять с публикации</span>
          </div>

          <div class="ctrl-btn__item ctrl-btn__item_delete mx-5 my-2"
            :class="{ 'ctrl-btn__item_disabled': !!!elements.length }"
            @click="isOpenConfirm = true">
              <span>Удалить</span>
          </div>
        </v-list>
      </v-menu>

      <transition name="fade">
        <div v-if="ctrlData.isChangeDateRange" 
          @click.stop="close($event)" 
          class="overlay">
            <div class="date-range-wrapper custom-header-color">
              <v-date-picker v-model="dates"
                color="#008FC5"
                header-color="#008FC5"
                no-title range
                elevation="2">
                  <v-col>
                    <v-btn elevation="0"
                      color="#0A467E"
                      width="100%"
                      tile dark
                      :disabled="!!!dates.length"
                      @click.stop="sendDates">
                        Сохранить
                    </v-btn>

                    <v-btn class="mt-2"
                      color="#0A467E"
                      width="100%"
                      outlined tile
                      @click.stop="closePicker">
                        Отменить
                    </v-btn>
                  </v-col>
              </v-date-picker>
            </div>
        </div>
      </transition>
    </v-row>

    <div v-if="isOpenConfirm" 
      class="overlay overlay_without-background"
      @click.stop="close($event)">
        <transition name="fade">
          <Confirm :items="items" 
            :question="`Вы действительно хотите удалить эти элементы? [${elements.length}]`"
            @agree="delItem"
            @close="isOpenConfirm = false" />
        </transition>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

import Confirm from '@/components/models/Confirm'

export default {
  name: 'TopCtrlMenuMobile',
  props: {
    ctrlData: {
      type: Object,
      require: true,
    },
    elements: {
      type: Array,
    },
    list: {
      type: Array,
    }
  },
  components: { Confirm },
  data: () => ({
    dates: [],
    isToPublic: true,
    isToTest: true,
    isOpenConfirm: false,
  }),
  computed: {
    items: function() {
      if ( this.elements.length ) {
        const arr = this.list.filter(el => this.elements.includes(el.id));
        return arr;
      }
      
    }
  },
  methods: {
    ...mapActions([
      'changeDates', 
      'changeStatus', 
      'deletePromo',
      'deleteProductFromCarousel',
      'changeDateProductsFromCarousel',
      'changeStatusProductsFromCarousel',
    ]),
    openPicker() {
      this.ctrlData.isChangeDateRange = true;
    },
    closePicker() {
      this.ctrlData.isChangeDateRange = false;
      this.dates = [];
    },
    async sendDates() {
      const payload = {
        ids: JSON.stringify(this.elements),
        start_date: this.dates[0],
        end_date: this.dates[1] || '',
      };
      
      if ( this.$route.name !== 'PromoCarousel' ) {
        await this.changeDates(payload);
      } else {
        await this.changeDateProductsFromCarousel(payload);
      }
      
      this.closePicker();
      this.$emit('clearElements');
      this.ctrlData.isAll = false;
    },
    async sendNewStatus({ arr, inTest = false, inPublic = false }) {
      if ( this.$route.name !== 'PromoCarousel' ) {
        const payload = {
          ids: JSON.stringify(arr),
          is_test: inTest,
          is_public: inPublic,
        }
        await this.changeStatus(payload);
      } else {
        const payload = {
          ids: JSON.stringify(arr),
          is_active: inPublic ? inPublic : false,
        }
        await this.changeStatusProductsFromCarousel(payload);
      }
      this.$emit('clearElements');
    },
    async delItem(arr) {
      const ids = JSON.stringify(arr);
      if ( this.$route.name !== 'PromoCarousel' ) {
        await this.deletePromo(ids);
      } else {
        await this.deleteProductFromCarousel(ids);
      }
      
      this.$emit('clearElements');
    },
    checkValidate(item) {
      if ( item.translates.length && 
        item.available_for.length ) 
      {
        return true
      } else {
        return false
      }
    },
    close(ev) {
      const target = ev.target;
      const isModal = target.closest('.confirm');
      const isPicker = target.closest('.date-range-wrapper');
      if ( isModal || isPicker ) return;

      this.isOpenConfirm = false;
      this.ctrlData.isChangeDateRange = false;
    }
  },
  watch: {
    elements() {
      this.isToPublic = true;
      this.isToTest = true;

      this.elements.forEach(el => {
        const obj = this.list.find(l => l.id === el);
        const isValid = this.checkValidate(obj)

        if ( this.$route.name === 'PromoCarousel' ) {
          if ( obj.is_test ) {
            this.isToPublic = true;
          }
        } else {
          if ( obj.is_test === false || !isValid ) {
            this.isToPublic = false;
          }
          if ( !isValid ) {
            this.isToTest = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ctrl-btn {
  &__item {
    position: relative;
    font-size: 16px;
    line-height: 22px;
    color: #0A467E;
    transition: all .3s ease;

    &_delete {
      color: #EB174B;
    }

    &_disabled {
      filter: contrast(0.25);
      pointer-events: none;
    }
  }
}

.top-ctrl-menu {
  position: sticky;
  top: -50px;
  z-index: 10;
  background-color: #fff;
}

.date-range-wrapper {
  position: absolute;
}

.overlay {
  position: fixed;
  display: grid;
  place-items: center;
  top: 0; left: 0;
  width: 100%; height: 100%;
  z-index: 10;
  background-color: rgba(0, 45, 85, 0.8);
}
</style>

<style lang="scss">
.custom-header-color {
  .v-date-picker-header {
    background-color: #008FC5;
    
    button { color: #fff !important; }
  }
}
</style>
